import React from "react";
import Layout from "../components/layout";
import SEO from "../components/common/SEO";
import Hero from "../components/common/Hero/PageHero";
import { Container, Row, Col } from "reactstrap";
import { content } from "../styles/modules/page.module.scss";

const ErrorPage = () => {
    return (
        <Layout pageId="404">
            <SEO title="Error" />
            <Hero parent="home" title="Page Not Found" />
            <section className={content}>
                <Container>
                    <Row>
                        <Col>
                            <p>Nothing was found for the requested page.</p>
                            <p><a href="/">Go Home</a></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
};

export default ErrorPage;